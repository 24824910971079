.nav {
  display: flex;
  justify-content: center;
  width: 100%;
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
}

.module {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw !important;
  background: var(--color-black);
  text-align: center;
  padding-top: 4vh;
}

.displayNone {
  display: none;
}

.hamburger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 33%;
}

.hamburger div {
  width: 40px !important;
  height: 4px;
  margin: 3px;
  border-radius: 20px;
  background: var(--color-white);
}

.hamburger .line1 {
  margin-right: 15px;
}
.hamburger .line2 {
  margin-left: 15px;
}
.hamburger .line3 {
  margin-right: 15px;
}
.hamburger .line4 {
  margin-left: 15px;
}

.hamburgerClose .line1 {
  transform: rotate(-45deg) translate(-7px, 7px);
}
.hamburgerClose .line2 {
  opacity: 0;
}
.hamburgerClose .line3 {
  transform: rotate(45deg) translate(-7px, -7px);
}
.hamburgerClose .line4 {
  opacity: 0;
}

.nav-ulist {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav-list {
  list-style: none;
  width: 100%;
  border-bottom: 1px solid var(--color-white);
  padding: 20px;
  cursor: pointer;
}

.nav-link {
  width: 100%;
  text-decoration: none;
  font-size: 30px;
  color: var(--color-primary);
}

.nav-list:hover {
  background: rgba(255, 255, 255, 0.5);
}

.openMenu {
  width: 50% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .hamburger-menu {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .hamburger-menu {
    width: 80%;
  }

  .hamburger div {
    width: 30px !important;
    height: 2px;
  }

  .hamburgerClose .line1 {
    transform: rotate(-45deg) translate(-6px, 6px);
  }

  .hamburgerClose .line3 {
    transform: rotate(45deg) translate(-6px, -6px);
  }

  .hamburger {
    margin-left: 50px;
  }
}
