.footer{
    background: var(--color-black);
    display: flex;
    color: var(--color-white);
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1vh 0;
}

.footer-image{
    width: 30px;
    margin-left: 20px;
}
