.header {
  background: var(--color-black);
  width: 100%;
  position: fixed;
  border-bottom: 2px solid white;
}

.header-width {
  width: 1200px;
  margin: auto;
}

.header-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.logo {
  text-align: center;
  width: 33%;
}

.header-nav {
  width: 33%;
}

.logo img {
  width: auto;
  height: 100px;
}

.name {
  color: var(--color-white);
  width: 33%;
}

.name img {
  width: 250px;
}

@media only screen and (max-width: 1200px) {
  .header-width {
    width: 80%;
  }

  .header-container {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 600px) {
  .name img {
    width: 100px;
  }
  
  .logo a img{
    height: 60px;
  }
}
