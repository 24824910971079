:root {
  --color-primary: #D4AF27;
  --color-second: #1B1B1B;
  --color-white: #fff;
  --color-black: #000;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
