.lunch_container {
  background: var(--color-second);
  width: 100%;
  height: 100%;
  padding-top: 150px;
  color: var(--color-white);
  padding-bottom: 80px;
}

.lunch_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lunch_header img {
  width: 120px;
}

.lunch_content {
  margin-top: 40px;
}

.lunch_data {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.reverse {
  flex-direction: row-reverse;
}

.lunch_img {
  width: 50%;
}

.lunch_text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-line
}

.lunch_text h2 {
  text-align: center;
}

.lunch_text h3 {
  text-align: center;
}

.lunch_item {
  border-bottom: 1px dashed var(--color-white);
  padding-bottom: 10px;
  margin: 20px;
}

.lunch_item_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.lunch_extrainfo {
  text-align: center;
  margin-bottom: 5px;
  white-space: pre-line
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 600px) {
  .lunch_container {
    padding-top: 80px;
  }

  .lunch_data {
    margin: 20px 0;
  }

  .lunch_img {
    display: none;
  }
}
