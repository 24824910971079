.bookTable {
  background-image: url('../assets/images/bookTable_1_edited.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
}

.bookTable-container {
  height: 80vh;
  width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  color: var(--color-white);
}

.bookTable-left {
  width: 50%;
  text-align: center;
}

.bookTable-left h2 {
  margin-bottom: 4vh;
}

.bookTable-left a {
  padding: 10px 40px;
  text-decoration: none;
  color: var(--color-black);
  background: var(--color-primary);
  border-radius: 20px;
}

.bookTable-left a:hover {
  transition: 0.2s ease-in-out;
  background: var(--color-black);
  color: var(--color-primary);
}

.bookTable-right {
  width: 50%;
  text-align: center;
}

.bookTable-right h2 {
  margin-bottom: 3vh;
}

.bookTable-right p {
  margin-bottom: 1vh;
}

.pri-color {
  color: var(--color-primary);
}

.pri-color::after {
  content: '\a';
  white-space: pre;
}

@media only screen and (max-width: 1200px) {
  .bookTable-container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .bookTable-container {
    flex-direction: column;
    padding-top: 30vh;
  }
  .bookTable-left{
    width: 100%;
    margin-bottom: 5vh;
  }
  .bookTable-right{
    width: 100%;
  }
}
