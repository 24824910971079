.quadri_container {
  background: var(--color-second);
  width: 100%;
  height: 100vh;
  padding-top: 150px;
  color: var(--color-white);
}

.quadri_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quadri_header img {
  width: 120px;
}

.quadri_text {
  text-align: center;
  width: 50%;
  margin: 30px auto 0 auto;
}

.quadri_text p {
  margin-top: 20px;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 600px) {
  .quadri_container {
    padding-top: 80px;
  }

  .quadri_text {
    width: 90%;
  }
}
