.home {
  position: relative;
}

.home-container {
  margin: auto;
  width: 1200px;
}

.home-header {
  display: flex;
  justify-content: space-between;
}

.home-header div {
  width: 33%;
}

.home-header img {
  width: 100%;
}

.home-nav {
  margin-top: 4vh;
}

.home-hero {
  height: 100vh;
  background-image: url("../assets/images/home_1_edited.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.home-hero h1 {
  color: var(--color-white);
}

.home-hero-links-container {
  margin-top: 2vh;
  display: flex;
  justify-content: space-around;
}

.home-hero-links {
  border: 1px solid var(--color-white);
  color: var(--color-white);
  width: 140px;
  padding: 10px 30px;
  text-decoration: none;
}

.home-hero-links:hover {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

/************** section 1 *************/

.home-section1 {
  display: flex;
}

.home-section1 div {
  width: 50%;
}

.home-section1-left {
  height: 60vh;
  background-image: url("../assets/images/home_2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home-section1-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5vw;
  text-align: center;
  background: var(--color-black);
  color: var(--color-white);
}

.home-section1-right img {
  margin-bottom: 10vh;
  width: 200px;
}

/************** section 2 *************/

.home-section2 {
  height: 40vh;
  background-image: url("../assets/images/home_3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/************** section 3 *************/

.home-section3 {
  display: flex;
}

.home-section3 div {
  width: 50%;
}

.home-section3-left {
  height: 60vh;
  background-image: url("../assets/images/home_4.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home-section3-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5vw;
  text-align: center;
  background: var(--color-black);
  color: var(--color-white);
}

.home-section3-right img {
  margin-bottom: 5vh;
  width: 200px;
}

.home-section3-right h3 {
  margin-bottom: 3vh;
}

.home-section3-right p {
  margin-bottom: 2vh;
}
.opening-hours-seperation {
  margin-right: 40px;
}

/************** media query *************/

@media only screen and (max-width: 1200px) {
  .home-container {
    width: 80%;
  }
  .home-hero {
    height: 50vh;
  }

  .home-section2 {
    height: 20vh;
  }

  .home-section3-right div{
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .home-container {
    width: 90%;
  }

  .home-hero {
    height: 40vh;
  }

  .home-section1-left {
    display: none;
  }

  .home-section1-right {
    width: 100% !important;
    padding: 20px 0;
  }

  .home-section1-right img {
    margin-bottom: 20px;
  }

  .home-section3-left {
    display: none;
  }

  .home-section3-right {
    width: 100% !important;
    padding: 20px 0;
  }

  .home-section3-right div{
    width: 90%;
  }
}
